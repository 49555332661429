import { API } from '@stoplight/elements';

import '@stoplight/elements/styles.min.css';
import './App.css';

const base_url = typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : ''

function App() {
  return (
    <div className="App">
      <API apiDescriptionUrl="/openapi.yaml" router="hash" tryItCorsProxy={`${base_url}/api/`} />
    </div>
  );
}

export default App;
